import React, { useState, useEffect, useRef } from 'react'

import { css, Global } from '@emotion/core'

import VideoModal from '../../components/videoModal/videoModal'

import {
  HeroSection,
  BackgroundWrapper,
  HeroBackground,
  HeroContent,
  PlayButton,
} from './hero.style'

const Hero = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  const heroBox = useRef(null)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    if (heroBox.current.getBoundingClientRect().bottom > 0)
      setScrollPos(window.scrollY / 3)
  }

  const openVideoModal = () => {
    setModalOpen(true)
  }

  const closeVideoModal = () => {
    setModalOpen(false)
  }

  const bgTranslation = css`
    transform: translate3d(0, ${scrollPos}px, 0);
  `

  return (
    <HeroSection id='hero' ref={heroBox}>
      <BackgroundWrapper css={bgTranslation}>
        <HeroBackground />
      </BackgroundWrapper>
      {modalOpen && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <HeroContent>
        <h1>59A</h1>
        <h2>Enabling next generation media companies with technology.</h2>
        <PlayButton onClick={openVideoModal} />
      </HeroContent>
      {modalOpen && (
        <VideoModal videoId='330804874' handleCloseVideo={closeVideoModal} />
      )}
    </HeroSection>
  )
}

export default Hero
