import React from 'react'

import {
  BenefitsSection,
  BenefitRow,
  BenefitHeading,
  BenefitDescription,
} from './benefits.style'

const Benefits = () => {
  return (
    <BenefitsSection id='benefits'>
      <h1>Benefits</h1>
      <BenefitRow className='performance'>
        <BenefitHeading>
          <h1>01 - Performance</h1>
        </BenefitHeading>
        <BenefitDescription>
          <h3>Improved campaign performance.</h3>
          <p>
            Limited human capability and capacity is stagnating the performance
            of programmatic, machines remove these barriers.
          </p>
        </BenefitDescription>
      </BenefitRow>
      <BenefitRow className='people'>
        <BenefitHeading>
          <h1>02 - People</h1>
        </BenefitHeading>
        <BenefitDescription>
          <h3>Reallocate resource to high value functions.</h3>
          <p>
            By automating repetitive operational tasks, reallocate resource to
            focus on high value services such as strategy, creative or data
            science.
          </p>
        </BenefitDescription>
      </BenefitRow>
      <BenefitRow className='platform'>
        <BenefitHeading>
          <h1>03 - Platform</h1>
        </BenefitHeading>
        <BenefitDescription>
          <h3>Trusted Business model.</h3>
          <p>
            Operational benefits pave way for transparency, trust and
            sustainability as standard.
          </p>
        </BenefitDescription>
      </BenefitRow>
    </BenefitsSection>
  )
}

export default Benefits
