import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'

import {
  VideoOverlay,
  VideoModalWrapper,
  VideoModalContainer,
  VideoClose,
} from './videoModal.style'

const videoDimensions = css`
  height: 100%;
  width: 100%;
`

const VideoModal = ({ videoId, handleCloseVideo }) => {
  return (
    <>
      <VideoOverlay />
      <VideoModalWrapper>
        <VideoClose onClick={handleCloseVideo} />
        <VideoModalContainer>
          <iframe
            css={videoDimensions}
            title={videoId}
            frameBorder='0'
            src={`//player.vimeo.com/video/${videoId}?autoplay=true`}
          />
        </VideoModalContainer>
      </VideoModalWrapper>
    </>
  )
}

VideoModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  handleCloseVideo: PropTypes.func.isRequired,
}

export default VideoModal
