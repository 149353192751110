import React, { useState, useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { navigate } from 'gatsby'

import { Nav, NavButton, Burger } from './navigation.style'

const scrollTo = target => {
  const targetPos = target.getBoundingClientRect().top + window.scrollY

  window.scrollTo({ top: targetPos, behavior: 'smooth' })
}

const Navigation = () => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const handleClick = (e, name) => {
    e.preventDefault()
    const target = document.getElementById(name)

    if (target) {
      setActive(false)
      scrollTo(target)
    } else {
      navigate(`/#${name}`)
    }
  }

  const openNav = () => {
    return active ? setActive(false) : setActive(true)
  }

  return (
    <Nav className={active ? 'active' : ''}>
      <ul>
        <li>
          <a href='#home' onClick={e => handleClick(e, 'hero')}>
            Home
          </a>
        </li>
        <li>
          <a href='#about' onClick={e => handleClick(e, 'about')}>
            About us
          </a>
        </li>
        <li>
          <a href='#benefits' onClick={e => handleClick(e, 'benefits')}>
            Benefits
          </a>
        </li>
        <li>
          <a href='#contact' onClick={e => handleClick(e, 'contact')}>
            Contact
          </a>
        </li>
        <li>
          <a
            href='https://precision-test.theprogrammaticplanner.com/login'
            target='_blank'
            rel='noopener noreferrer'
          >
            Precision
          </a>
        </li>
      </ul>
      <NavButton onClick={openNav}>
        <Burger className='bun-top' />
        <Burger className='patty' />
        <Burger className='bun-bottom' />
      </NavButton>
    </Nav>
  )
}

export default Navigation
