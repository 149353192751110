import React from 'react'
import { css } from '@emotion/core'

import build from '../../assets/images/icons/build.png'
import integrate from '../../assets/images/icons/integrate.png'
import optimise from '../../assets/images/icons/optimise.png'
import plan from '../../assets/images/icons/plan.png'
import report from '../../assets/images/icons/report.png'
import window from '../../assets/images/icons/window.png'

import {
  PrecisionSection,
  PrecisionContent,
  PrecisionCardContainer,
  PrecisionCard,
  PrecisionCardTop,
  PrecisionCardBottom,
} from './precision.style'

const dataCards = [
  {
    name: 'Plan',
    description:
      'Design high precision media and creative algorithms to power your campaigns.',
    icon: plan,
  },
  {
    name: 'Integrate',
    description:
      'Integrate and manage high precision media and creative algorithms via one platform.',
    icon: integrate,
  },
  {
    name: 'Build',
    description: 'The process of making code is fully automated.',
    icon: build,
  },
  {
    name: 'Optimise',
    description: 'Automated real time atomic optimization.',
    icon: optimise,
  },
  {
    name: 'Report',
    description:
      'Easy to use dashboard. Instantly create reports and share data.',
    icon: report,
  },
  {
    name: 'Window',
    description:
      'Unique live, transparent preformance and algorithm decision making dashboard.',
    icon: window,
  },
]

const Precision = () => {
  const renderCards = () => {
    return dataCards.map(({ name, description, icon }) => {
      return (
        <PrecisionCard key={name}>
          <PrecisionCardTop>
            <h3>{name}</h3>
            <div
              css={css`
                text-align: right;
              `}
            >
              <i
                css={css`
                  background-image: url(${icon});
                `}
              />
            </div>
          </PrecisionCardTop>
          <PrecisionCardBottom>
            <p>{description}</p>
          </PrecisionCardBottom>
        </PrecisionCard>
      )
    })
  }

  return (
    <PrecisionSection id='precision'>
      <PrecisionContent>
        <h1>How Precision works</h1>
        <h3>
          Precision is integrated with existing advertising technology and
          leading machine learning platforms, supercharging your campaign.
        </h3>
        <PrecisionCardContainer>{renderCards()}</PrecisionCardContainer>
      </PrecisionContent>
    </PrecisionSection>
  )
}

export default Precision
