const colourPrimary = `#F77904`
const colourSecondary = `#72B6BF`
const colourAlpha = `#263B52`
const colourBeta = `#3D3D3D`
const colourDelta = `#F7F5EE`

const colourBackground = `#f9f6f2`
const colourBackgroundBeta = `#fff`
const colourBackgroundNeg = `#333`
const colourBackgroundDelta = `#DBDBDB`

const colourHeader = `#3D3D3D`
const colourHeaderBeta = `#F7F5EE`

const colourCopy = `#3D3D3D`
const colourCopyNeg = `#F7F5EE`

export {
  colourPrimary,
  colourSecondary,
  colourAlpha,
  colourBeta,
  colourDelta,
  colourBackground,
  colourBackgroundBeta,
  colourBackgroundNeg,
  colourBackgroundDelta,
  colourHeader,
  colourHeaderBeta,
  colourCopy,
  colourCopyNeg,
}
