import styled from '@emotion/styled'
import { colourHeaderBeta } from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/mission-bg.png'

const MissionSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${mediaQuery.tablet} {
    flex-wrap: wrap;
  }
`

const MissionCard = styled.div`
  padding: 20px;
  flex-grow: 1;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: ${colourHeaderBeta};
  }

  h3 {
    text-align: center;
    color: ${colourHeaderBeta};
  }

  ${mediaQuery.tablet} {
    width: 100%;
  }
`

export { MissionSection, MissionCard }
