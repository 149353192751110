import styled from '@emotion/styled'
import { colourHeaderBeta, colourDelta } from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/contact-bg.png'

const ContactSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1,
  p {
    color: ${colourHeaderBeta};
  }
`

const ContactForm = styled.form`
  padding: 40px;
  min-width: 40%;
  background-color: ${colourDelta};
  text-align: left;

  h3 {
    text-align: left;
    margin-bottom: 20px;
  }

  input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 25px;
  }

  button {
    border: none;
    text-align: left;
    padding: 10px 20px;
    background-color: #fc0;
    font-size: 14px;
  }

  ${mediaQuery.tablet} {
    min-width: none;
    width: 100%;
  }
`

export { ContactSection, ContactForm }
