import styled from '@emotion/styled'
import { colourBackground } from '../../emotion/colour.style'

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background-color: ${colourBackground};
  z-index: 2;
`

export default HeaderContainer
