import React from 'react'

import { MissionSection, MissionCard } from './mission.style'

const missionCards = [
  {
    title: 'Technology',
    subTitle: 'Build and manage the plan you always wanted.',
  },
  {
    title: 'Resource',
    subTitle: 'Enhance capability and capacity.',
  },
  {
    title: 'Value',
    subTitle: 'Differentiate your services.',
  },
  {
    title: 'Cost',
    subTitle: 'Empowering sustainable business models.',
  },
]

const Mission = () => {
  return (
    <MissionSection>
      {missionCards.map(({ title, subTitle }) => (
        <MissionCard key={`${title}-${subTitle}`}>
          <h2>{title}</h2>
          <h3>{subTitle}</h3>
        </MissionCard>
      ))}
    </MissionSection>
  )
}

export default Mission
