import styled from '@emotion/styled'
import {
  colourPrimary,
  colourBackgroundBeta,
  colourHeaderBeta,
} from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/hero-bg.png'

const HeroSection = styled.section`
  position: relative;
  display: flex;
  min-height: 100vh;
  align-items: center;
  overflow: hidden;
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HeroBackground = styled.div`
  position: absolute;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${mediaQuery.tablet} {
    transform: translate3d(0, 0, 0);
  }
`

const HeroContent = styled.div`
  max-width: 50%;
  z-index: 1;

  h1,
  h2 {
    color: ${colourHeaderBeta};
  }

  ${mediaQuery.tablet} {
    max-width: 100%;
  }
`

const PlayButton = styled.i`
  position: relative;
  height: 100px;
  width: 100px;
  background-color: ${colourBackgroundBeta};
  border-radius: 50%;
  display: block;
  margin: 50px auto 0;
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 50px solid ${colourPrimary};
    margin: 20px 20px 20px 30px;
  }
`

export {
  HeroSection,
  BackgroundWrapper,
  HeroBackground,
  HeroContent,
  PlayButton,
}
