import styled from '@emotion/styled'

import {
  colourBackgroundNeg,
  colourBackground,
} from '../../emotion/colour.style'

const VideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${colourBackgroundNeg};
  opacity: 0.85;
  z-index: 3;
`

const VideoModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`

const VideoModalContainer = styled.div`
  position: relative;
  padding: 10px;
  height: 80%;
  width: 80%;
`

const VideoClose = styled.i`
  position: absolute;
  height: 50px;
  width: 50px;
  top: 20px;
  right: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    height: 2px;
    width: 40px;
    background-color: ${colourBackground};
    transform-origin: 0 50%;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

export { VideoOverlay, VideoModalWrapper, VideoModalContainer, VideoClose }
