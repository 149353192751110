import styled from '@emotion/styled'
import {
  colourHeader,
  colourHeaderBeta,
  colourDelta,
  colourBackgroundDelta,
} from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/precision-bg.png'

const PrecisionSection = styled.section`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const PrecisionContent = styled.div`
  h1,
  h3 {
    color: ${colourHeaderBeta};
  }

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  h3 {
    line-height: 1.2;
    margin-bottom: 40px;
  }
`

const PrecisionCardContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.tablet} {
    display: block;
  }
`

const PrecisionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  flex-basis: 16.5%;

  ${mediaQuery.tablet} {
    margin-bottom: 20px;
  }
`

const PrecisionCardTop = styled.div`
  background-color: ${colourDelta};
  padding: 10px;
  height: 150px;

  h3 {
    color: ${colourHeader};
    font-weight: 600;
  }

  i {
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`

const PrecisionCardBottom = styled.div`
  background-color: ${colourBackgroundDelta};
  padding: 20px 10px;
  text-align: center;
  flex-grow: 1;
`

export {
  PrecisionSection,
  PrecisionContent,
  PrecisionCardContainer,
  PrecisionCard,
  PrecisionCardTop,
  PrecisionCardBottom,
}
