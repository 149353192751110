import styled from '@emotion/styled'
import { colourHeaderBeta } from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/statement-bg.png'

const StatementSection = styled.section`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const StatementContent = styled.div`
  max-width: 75%;
  text-align: center;

  h1,
  h3 {
    color: ${colourHeaderBeta};
  }

  h1 {
    margin-bottom: 50px;
  }

  h3 {
    line-height: 1.2;
  }

  ${mediaQuery.tablet} {
    max-width: none;
  }
`

export { StatementSection, StatementContent }
