import React from 'react'

import HeaderContainer from './header.style'

import Navigation from '../navigation/navigation'

const Header = () => (
  <HeaderContainer>
    <Navigation />
  </HeaderContainer>
)

export default Header
