import React from 'react'

import { AboutSection, AboutContent } from './about.style'

const About = () => {
  return (
    <AboutSection id='about'>
      <AboutContent>
        <h1>Who we are</h1>
        <h3>
          A technology company comprised of digital media specialists, data
          analysts and software engineers that have automated the planning and
          buying parts of media, creative & intelligence processes where
          machines outdo humans.
        </h3>
      </AboutContent>
    </AboutSection>
  )
}

export default About
