/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { StatementSection, StatementContent } from './statement.style'

const Statement = () => {
  return (
    <StatementSection id='statement'>
      <StatementContent>
        <h1>What we do</h1>
        <h3>
          We have done that by building <strong>Precision</strong> - a
          transparent automation platform that enables the production and
          management of media and create algorithms at scale in real time.
        </h3>
        <h3>
          59A is here to empower the next generation of digital media planning
          and buying where optimal performance and efficiency are not just
          clichés, but guaranteed outcomes.
        </h3>
      </StatementContent>
    </StatementSection>
  )
}

export default Statement
