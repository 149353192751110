import styled from '@emotion/styled'
import { colourHeaderBeta } from '../../emotion/colour.style'
import mediaQuery from '../../emotion/utils.style'

import bgImg from '../../assets/images/backgrounds/benefits-bg.png'

const BenefitsSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1 {
    color: ${colourHeaderBeta};
    text-align: center;
    margin-bottom: 40px;
  }
`

const BenefitRow = styled.div`
  display: flex;

  ${mediaQuery.tablet} {
    display: block;
  }
`

const BenefitHeading = styled.div`
  flex-basis: 66%;
  padding: 10px;

  h1 {
    text-align: left;

    .performance & {
      color: ${colourHeaderBeta};
    }

    .people & {
      color: ${colourHeaderBeta};
    }

    .platform & {
      color: ${colourHeaderBeta};
    }
  }

  ${mediaQuery.tablet} {
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
`

const BenefitDescription = styled.div`
  flex-basis: 33%;
  padding: 10px;

  .performance & {
    border-top: 1px solid ${colourHeaderBeta};
  }

  .people & {
    border-top: 1px solid ${colourHeaderBeta};
  }

  .platform & {
    border-top: 1px solid ${colourHeaderBeta};
  }

  h3 {
    font-weight: 600;

    .performance & {
      color: ${colourHeaderBeta};
    }

    .people & {
      color: ${colourHeaderBeta};
    }

    .platform & {
      color: ${colourHeaderBeta};
    }
  }
`

export { BenefitsSection, BenefitRow, BenefitHeading, BenefitDescription }
