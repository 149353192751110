import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../sections/hero/hero'
import Mission from '../sections/mission/mission'
import About from '../sections/about/about'
import Statement from '../sections/statement/statement'
import Precision from '../sections/precision/precision'
import Benefits from '../sections/benefits/benefits'
import Contact from '../sections/contact/contact'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title='59A' keywords={[`gatsby`, `application`, `react`]} />
      <Hero />
      <Mission />
      <About />
      <Statement />
      <Precision />
      <Benefits />
      <Contact />
    </Layout>
  )
}

export default IndexPage
