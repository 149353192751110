import React from 'react'

import { ContactSection, ContactForm } from './contact.style'

const Contact = () => {
  return (
    <ContactSection id='contact'>
      <h1>Contact us</h1>
      <p>
        If you would like to discover more about 59A and our services, please
        contact us using the form below:
      </p>
      <ContactForm
        method='POST'
        action='https://formspree.io/adamray24@gmail.com'
      >
        <h3>Contact Us</h3>
        <input type='hidden' name='_subject' value='59A' />
        <input type='text' name='name' placeholder='Name' />
        <input type='email' name='email' placeholder='Email' />
        <button type='submit'>SUBMIT</button>
      </ContactForm>
    </ContactSection>
  )
}

export default Contact
